.frame {
  align-items: center;
  background-color: #ffffff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  position: relative;
}

.frame .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .image {
  height: 591px;
  left: 5384px;
  position: absolute;
  top: 2365px;
  width: 510px;
}

.frame .vector {
  height: 338px;
  left: 6159px;
  position: absolute;
  top: 3494px;
  width: 350px;
}

.frame .img {
  height: 345px;
  left: 275px;
  position: absolute;
  top: 385px;
  width: 204px;
}

.frame .vector-2 {
  height: 237px;
  left: 5723px;
  position: absolute;
  top: 2115px;
  width: 246px;
}

.frame .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 98px;
  justify-content: center;
  position: relative;
  width: 390px;
}

.frame .img-2 {
  height: 35px;
  position: relative;
  width: 288px;
}

.frame .div-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper {
  color: #292929;
  font-family: "Proxima Nova-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.frame .button {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(to bottom right, rgb(59, 123, 210) 0%, rgb(208, 30, 167) 100%);
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 18px 10px 22px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-2 {
  color: #ffffff;
  font-family: "Proxima Nova-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .tabler-icon-arrow-down-right {
  background-image: url(../../../static/img/tabler-icon-arrow-down-right-2.svg) !important;
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.frame .button-2 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #7855c1;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 85px;
  position: relative;
  width: 100%;
}

.frame .tabler-icon {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.frame .text-wrapper-3 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(59, 123, 210) 0%, rgb(208, 30, 167) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Proxima Nova-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -2px;
  position: relative;
  text-fill-color: transparent;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 524px;
  padding: 15px 0px;
  position: relative;
  width: 100%;
}

.frame .div-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  margin-bottom: -2px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.frame .p {
  color: #393434;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  /*margin-top: -1px;*/
  margin-top: 16px;
  position: relative;
  text-align: center;
  width: 315px;
}

.frame .group-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #2ea6da;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 98px;
  position: relative;
  width: 100%;
}

.frame .group {
  height: 26px;
  position: relative;
  width: 98px;
}

.frame .img-3 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 18px;
}

.frame .text-wrapper-4 {
  color: #ffffff;
  font-family: "Proxima Nova-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 26px;
  left: 30px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .div-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #00c307;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 98px;
  position: relative;
  width: 100%;
}

.frame .group-2 {
  height: 26px;
  position: relative;
  width: 107px;
}

.frame .button-3 {
  align-items: center;
  align-self: stretch;
  background-color: #282b2c;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 98px;
  position: relative;
  width: 100%;
}

.frame .group-3 {
  height: 22px;
  position: relative;
  width: 103px;
}

.frame .img-4 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 18px;
}

.frame .text-wrapper-5 {
  color: #ffffff;
  font-family: "Object Sans-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  left: 28px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .div-7 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 279px;
  position: relative;
  width: 425px;
}

.frame .rectangle {
  background: linear-gradient(180deg, rgb(119.67, 116, 205) 0%, rgb(178, 49, 176) 100%);
  height: 279px;
  margin-left: -826px;
  margin-right: -826px;
  position: relative;
  width: 2077px;
}

.frame .div-8 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 64px;
  position: absolute;
  top: 38px;
}

.frame .div-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.frame .text-wrapper-6 {
  color: #ffffff;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-7 {
  color: #ffffff;
  font-family: "Proxima Nova-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 41.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.frame .button-4 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 22px;
  position: relative;
  width: 298px;
}

.frame .text-wrapper-8 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(0, 159, 226) 0%, rgb(255, 0, 153) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Proxima Nova-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -2px;
  position: relative;
  text-fill-color: transparent;
  white-space: nowrap;
  width: fit-content;
}

.frame .tabler-icon-arrow-instance {
  background-image: url(../../../static/img/tabler-icon-arrow-down-right-1.svg) !important;
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.frame .button-5 {
  align-items: center;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 22px;
  position: relative;
  width: 298px;
}

.frame .text-wrapper-9 {
  color: #ffffff;
  font-family: "Proxima Nova-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 1231px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-10 {
  color: #292929;
  font-family: "Proxima Nova-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.frame .element {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0px 0.25px 3.27px #9407d009, 0px 0.6px 7.85px #9407d00b, 0px 1.13px 14.78px #9407d00d,
    0px 2.01px 26.36px #9407d00e, 0px 3.76px 49.3px #9407d010, 0px 9px 118px #9407d014;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 54px;
  padding: 24px;
  position: relative;
  width: 100%;
}

.frame .div-13 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.frame .APPOMART-BELGRADE {
  height: 51.66px;
  position: relative;
  width: 146.61px;
}

.frame .div-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.frame .text-wrapper-11 {
  color: var(--blue);
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  width: fit-content;
}

.frame .text-wrapper-12 {
  color: #696969;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame .div-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.frame .image-2 {
  height: 24px;
  position: relative;
  width: 31px;
}

.frame .rectangle-2 {
  height: 24px;
  object-fit: cover;
  position: relative;
  width: 31px;
}

.frame .APPOMART-SINGAPORE {
  height: 51.66px;
  position: relative;
  width: 150.48px;
}

.frame .text-wrapper-13 {
  color: #696969;
  font-family: "Proxima Nova-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .APPOMART-SAINT {
  height: 51.66px;
  position: relative;
  width: 250.55px;
}

.frame .APPOMART-TEL-AVIV {
  height: 51.31px;
  position: relative;
  width: 146.61px;
}
