.vladimir {
    align-items: center;
    /*background-color: #f0f2f8;*/
    border: 1px none;
    display: flex;
    flex-direction: column;
    /*gap: 40px;*/
    height: 1389px;
    overflow: hidden;
    position: relative;
}

.vladimir .rectangle {
    background-color: #160d26;
    height: 332px;
    left: -365px;
    position: absolute;
    top: 676px;
    width: 1781px;
}

.vladimir .ellipse {
    background-color: #766394;
    border-radius: 75.5px;
    filter: blur(125px);
    height: 151px;
    left: 66px;
    position: absolute;
    top: 727px;
    width: 151px;
}

.vladimir .div {
    background-color: #160d26;
    height: 229px;
    margin-left: -788px;
    margin-right: -788px;
    position: relative;
    width: 1966px;
}

.vladimir .frame-1-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 43px;
    padding: 0px 20px;
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

.vladimir .welcome-to-my {
    color: #160d26;
    font-family: "Object Sans-Regular", Helvetica, serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 348px;
}

.vladimir .frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
}

.vladimir .button {
    align-items: center;
    align-self: stretch;
    background-color: #282b2c;
    border-radius: 32px;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 98px;
    position: relative;
    width: 100%;
}

.vladimir .img2 {
    height: 18px;
    position: relative;
    width: 18px;
}

.vladimir .text-wrapper {
    color: #ffffff;
    font-family: "Object Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.vladimir .button-2-1 {
    align-items: center;
    align-self: stretch;
    background-color: #2ea6da;
    border-radius: 32px;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 98px;
    position: relative;
    width: 100%;
}

.vladimir .img-2 {
    height: 20px;
    position: relative;
    width: 20px;
}

.vladimir .button-3 {
    align-items: center;
    align-self: stretch;
    background-color: #00c307;
    border-radius: 32px;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 98px;
    position: relative;
    width: 100%;
}

.vladimir .frame-3-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 140px;
    justify-content: flex-end;
    padding: 0px 20px;
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

.vladimir .text-wrapper-2-1 {
    color: #98ebad;
    font-family: "Object Sans-Heavy", Helvetica;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 36.4px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    margin-top: 24px;
}

.vladimir .button-4 {
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    border-color: #98ebad;
    border-radius: 32px;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}

.vladimir .text-wrapper-3-1 {
    color: #ffffff;
    font-family: "Object Sans-Heavy", Helvetica;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.vladimir .frame-4-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 127px;
    padding: 0px 20px;
    position: relative;
    width: 100%;
    margin-bottom: 50px;
}

.vladimir .button-5 {
    align-items: center;
    align-self: stretch;
    background-color: #ffffff;
    border-radius: 32px;
    box-shadow: 0px 4px 8px #53545e33;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}

.vladimir .image {
    height: 20px;
    position: relative;
    width: 26px;
}

.vladimir .text-wrapper-4 {
    color: #000000;
    font-family: "Object Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -2px;
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
}

.vladimir .rectangle-2 {
    height: 20px;
    object-fit: cover;
    position: relative;
    width: 26px;
}

.vladimir .frame-5-1 {
    background-color: #f0f2f8;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 450px;
    padding: 30px 0px 30px;
    position: relative;
    width: 100%;
}

.vladimir .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.vladimir .text-wrapper-5-1 {
    color: #160d26;
    font-family: "Object Sans-Heavy", Helvetica;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 36.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.vladimir .p {
    color: #160d26;
    font-family: "Object Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    position: relative;
    text-align: center;
    width: 315px;
}

.vladimir .frame-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    margin-bottom: -18px;
    padding: 0px 20px;
    position: relative;
    width: 100%;
}

.vladimir .text-wrapper-6 {
    color: #000000;
    font-family: "Object Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.vladimir .button-6 {
    align-items: center;
    align-self: stretch;
    border: 2px solid;
    border-color: #98ebad;
    border-radius: 32px;
    box-shadow: 0px 4px 8px #53545e33;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 84px;
    position: relative;
    width: 100%;
}

.vladimir .mask-group {
    height: 229px;
    left: 0;
    position: absolute;
    top: 0;
    width: 390px;
}

.vladimir .ellipse-2 {
    height: 100px;
    left: 145px;
    object-fit: cover;
    position: absolute;
    top: 46px;
    width: 100px;
}

.vladimir .text-wrapper-7 {
    color: #98ebad;
    font-family: "Object Sans-Heavy", Helvetica;
    font-size: 28px;
    font-weight: 400;
    height: 29px;
    left: 77px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 169px;
    white-space: nowrap;
}

.vladimir .vector {
    height: 33px;
    left: 314px;
    position: absolute;
    top: 800px;
    width: 31px;
}

.vladimir .mask-group1 {
    /*height: 229px;*/
    /*width: 390px;*/
    /*background-image: url("/img/mask-group.png");*/
    position: relative;
    margin: auto;
    background-size: cover;
}


.vladimir .div1 {
    background-color: #160d26;
    height: 229px;
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

.top-image {
}

.vladimir .image-background1 {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -100px;
    height: 400px;
    width: 400px;
    background-image: url("/img/Ellipse 45.png");
    background-size: cover;
}

.vladimir .ellipse-2-1 {
    height: 100px;
    position: relative;
    width: 100px;
    /*top: 46px;*/
    top: 146px;
    display: block;
    margin: auto;
    z-index: 2;
}

.vladimir .text-wrapper-7-1 {
    color: #98ebad;
    font-family: "Object Sans-Heavy", Helvetica;
    font-size: 28px;
    font-weight: 800;
    height: 29px;
    width: 100%;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 169px;
    white-space: nowrap;
    display: block;
}