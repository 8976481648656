#app {
  height: 100%;
}

.frame {
  align-items: center;
  background-color: #ffffff;
  border: none;
  height: 100%;
}

.center {
  position: absolute;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  max-width: 700px;
  width: 85%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.center img {
  width: 100%;
}

.loading-container {
  max-width: 400px;
  left: 0;
  right: 0;
  margin: auto;
}

.loading {
  top: 1em;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  position: relative;
  width: 25%;
  padding: 12.5%;
  margin-top: -8%;
  box-sizing: border-box;
}

.loading-center {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 35%;
  margin: auto;
  padding-top: 0.1%;
  padding-bottom: 0.1%;
  filter: contrast(20);
  background: white;
}

.loading-center .dot-container {
  width: 100%;
  height: 100%;
}

.loading-center .dot {
  position: absolute;
  width: 11%;
  height: 31.4%;
  margin-left: 10%;
  background: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
  filter: blur(0.2rem)
}
.loading-center .dots {
  position: relative;
  transform: translateX(0);
  margin-top: 10%;
  margin-left: 20%;
  height: 31.5%;
  animation: dots 2.8s infinite;
}
.loading-center .dots span {
  display: block;
  float: left;
  width: 13.7%;
  height: 100%;
  margin-left: 15%;
  background: #000;
  border-radius: 50%;
  filter: blur(0.2rem)
}

@keyframes dot {
  50% {
    transform: translateX(650%);
  }
}

@keyframes dots {
  50% {
    transform: translateX(-25%);
  }
}